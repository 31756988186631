























































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop } from 'vue-property-decorator';
import { getComponent } from '@/utils/helpers';
import TasqJob from '../../interfaces/tasqs/TasqJob';
import { mixins } from 'vue-class-component';
import { DateTime } from 'luxon';
import operationsModule from '@/store/modules/operationsModule';
import DataLoading from '../../lib/mixins/dataLoading';
import setpointModule from '@/store/modules/setpointModule';
import setpointV2Module from '@/store/modules/setpointV2Module';

@Component({
  components: {
    SpinnerLoader: () => getComponent('loaders/SpinnerLoader'),
    LineChart: () => import('@/lib/charts/lineChart'),
  },
})
export default class SetpointFeedbackInfo extends mixins(DataLoading) {
  @Prop({ required: true }) tasq!: TasqJob;

  chartLoaded = false;
  showInstructionsIndex = -1;

    get getUplift(): any {
		try {
			var rounded_value = setpointV2Module.uplift.toFixed(0)
			return rounded_value;
		} catch (err) {
			return setpointV2Module.uplift;
		}
      
    }

    get getDefermentUnits(): any {
      return setpointV2Module.unitType;
    }

  getSetpoinInstructionsArrowDropDirection(index) {
	  if (index == this.showInstructionsIndex) {
		  return "arrow_drop_down"
	  }
	  return "arrow_drop_up"
  }



  getTasqOutput(outputVal) {

// Tasq Output 1 = Current Close Timer
// Tasq Output 2 = Flow Timer
// Tasq Output 3 = Current Afterflow Time
// Tasq Output 4 = Recommended Afterflow Time
// Tasq Output 5 = Current Flowrate at Close
// Tasq Output 7 = Current Arrival Time Limit Max
// Tasq Output 8 = Recommended Arrival Time Limit Max
// Tasq Output 9 = Current Load Factor at Open
// Tasq Output 10 = Recommended Load Factor at Open
// Tasq Output 11 = Current Hold Plunger Fall Timer
// Tasq Output 12 = Recommended Hold Plunger Fall Time
// Tasq Output 13 = Current Close Timer
// Tasq Output 14 = Recommended Close Timer

	
	if (outputVal == 1) {
		return setpointV2Module.tasqSetpointData["Tasq Output 1"]
	} else if (outputVal == 2) {
		return setpointV2Module.tasqSetpointData["Tasq Output 2"]
	} else if (outputVal == 3) {
		return setpointV2Module.tasqSetpointData["Tasq Output 3"]
	} else if (outputVal == 4) {
		return setpointV2Module.tasqSetpointData["Tasq Output 4"]
	} else if (outputVal == 5) {
		return setpointV2Module.tasqSetpointData["Tasq Output 5"]
	} else if (outputVal == 7) {
		return setpointV2Module.tasqSetpointData["Tasq  Output 7"]
	} else if (outputVal == 8) {
		return setpointV2Module.tasqSetpointData["Tasq Output 8"]
	} else if (outputVal == 9) {
		return setpointV2Module.tasqSetpointData["Tasq Output 9"]
	} else if (outputVal == 10) {
		return setpointV2Module.tasqSetpointData["Tasq Output 10"]
	} else if (outputVal == 11) {
		return setpointV2Module.tasqSetpointData["Tasq Output 11"]
	} else if (outputVal == 12) {
		return setpointV2Module.tasqSetpointData["Tasq Output 12"]
	} else if (outputVal == 13) {
		return setpointV2Module.tasqSetpointData["Tasq Output 13"]
	} else if (outputVal == 14) {
		return setpointV2Module.tasqSetpointData["Tasq Output 14"]
	}
	  return '-'
  }


  chartData: any = {
    labels: [],
    datasets: [{
      label: '',
      data: [],
      borderColor: '#2CE6C2',
      borderWidth: 1.5,
      pointBackgroundColor: '#0076FF',
      pointBorderColor: '#0076FF',
      pointRadius: 0,
      backgroundColor: 'rgba(44,230,194,0.1)',
      fill: true,
      showLine: true,
      tension: 0,
      spanGaps: true,
      pointHitRadius: 5,
      pointHoverRadius: 0,
    }],
  };

  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    events: ['click'],
    legend: {
      display: false,
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    animation: {
      duration: 0,
    },
    hover: {
      mode: 'x',
      animationDuration: 0,
    },
    responsiveAnimationDuration: 0,
    tooltips: {
      enabled: true,
      displayColors: false,
      backgroundColor: '#fff',
      titleFontColor: '#8C8FB0',
      bodyFontColor: '#000750',
      bodyFontStyle: 'bold',
      titleAlign: 'center',
      bodyAlign: 'center',
      xPadding: 5,
      yPadding: 5,
      callbacks: {
        title: (tooltipItems) => `${new Date(tooltipItems[0].xLabel).toLocaleString('en-us', {
          month: '2-digit',
          day: '2-digit',
        })} ${new Date(tooltipItems[0].xLabel).toLocaleTimeString('en-us', {
          hour: '2-digit', minute: '2-digit',
        })}`,
      },
    },
    scales: {
      xAxes: [{
        type: 'time',
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: 'rgb(113, 122, 139)',
          minRotation: 28,
          autoSkipPadding: 5,
          maxTicksLimit: 20,
        },
        time: {
          unit: 'day',
          displayFormats: {
            hour: 'h:mm A',
            day: 'MM/DD',
          },
        },
      }],
      yAxes: [{
        gridLines: {
          color: 'rgb(113, 122, 139)',
        },
        ticks: {
          fontColor: 'rgb(113, 122, 139)',
          maxTicksLimit: 4,
          fontStyle: 'bold',
          minRotation: 0,
          maxRotation: 0,
          callback: (value) => Number(value).toFixed(),
        },
      }],
    },
    annotation: {
      annotations: [
		  {
            type: "line",
            mode: "vertical",
            scaleID: "x-axis-0",
            value: "2021-08-21T13:35",
            borderColor: "rgba(255,255,255,0.7)",
			borderDash: [9, 7],
            // label: {
            //   content: "TODAY",
            //   enabled: true,
            //   position: "top"
            // }
		  }
	  ],
    },
  }




// Flow timer close : open to close, flow rate close : close_flowrate, time limits: not there yet, case line open : open_lift pressure, closed timer: close to open


// Uplift is best MCF - current MCF

  isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  get setpointNameRowData() {
	  var results: any[] = []
	  if (this.isEmpty(setpointV2Module.tasqSetpointData)) {
		  return results
	  }
	  var mapSetpointNames = {
		  "open_to_close": "Flow timer close",
		  "close_flowrate": "Flowrate close",
		  "flowrate_time_limit": "Flowrate low time limit",
		  "evaluation_time_limit": "Evaluation time limit",
		  "open_lift_pressure": "Case-Line Open",
		  "close_to_open": "Closed timer",
		  "Open Timer": "Flow timer close",
		  "Close time": "Closed timer",
		  "Close Time": "Closed timer",
		  "Closed timer": "Closed timer",
		  "Plunger Fall Delay": "Hold plunger fall timer"
	  }

	  results.push("Afterflow timer close")
	  results.push("Flowrate low time limit")
	  results.push("Closed timer")
	//   for (const [key, value] of Object.entries(setpointV2Module.tasqSetpointData["Current Setpoints"])) {
	// 	if (mapSetpointNames[key] != null) {
	// 		results.push(mapSetpointNames[key])
	// 	}
		
		
	//   }
	  return results
  }

  get setpointEstimatedCurrentRowData() {

	  var results: any[] = []
	  if (this.isEmpty(setpointV2Module.tasqSetpointData)) {
		  return results
	  }
	  var mapSetpointNames = {
		  "open_to_close": "Flow timer close",
		  "close_flowrate": "Flowrate close",
		  "flowrate_time_limit": "Flowrate low time limit",
		  "evaluation_time_limit": "Evaluation time limit",
		  "open_lift_pressure": "Case-Line Open",
		  "close_to_open": "Closed timer",
		  "Open Timer": "Flow timer close",
		  "Close time": "Closed timer",
		  "Close Time": "Closed timer",
		  "Closed timer": "Closed timer",
		  "Plunger Fall Delay": "Hold plunger fall timer"
	  }

	  results.push("-")
	  results.push("-")
	  results.push("-")
	//   for (const [key, value] of Object.entries(setpointV2Module.tasqSetpointData["Current Setpoints"])) {
	// 	if (mapSetpointNames[key] != null) {
	// 		// @ts-ignore
	// 		results.push(parseFloat(value).toFixed(2))
	// 	}
		
	//   }
	  return results
  }

  get setpointRecommendedRowData() {
	  var results: any[] = []
	  if (this.isEmpty(setpointV2Module.tasqSetpointData)) {
		  return results
	  }
	  var mapSetpointNames = {
		  "open_to_close": "Flow timer close",
		  "close_flowrate": "Flowrate close",
		  "flowrate_time_limit": "Flowrate low time limit",
		  "evaluation_time_limit": "Evaluation time limit",
		  "open_lift_pressure": "Case-Line Open",
		  "close_to_open": "Closed timer",
		  "Open Timer": "Flow timer close",
		  "Close time": "Closed timer",
		  "Close Time": "Closed timer",
		  "Closed timer": "Closed timer",
		  "Plunger Fall Delay": "Hold plunger fall timer"
	  }
	  results.push("Disable")
	  results.push("0.5")
	  results.push("Disabled")
	//   for (const [key, value] of Object.entries(setpointV2Module.tasqSetpointData["Optimal Setpoints"])) {
	// 	if (mapSetpointNames[key] != null) {
	// 		// @ts-ignore
	// 		results.push(parseFloat(value).toFixed(2))
	// 	}
		
	//   }
	  return results
  }




  async created() {
	await this.changeChartTime();


	const fromTime = DateTime.local().minus({ days: 1 }).toISO();
	const toTime = DateTime.local().toISO();
	if (this.getDefermentUnits == "...") {
		operationsModule.getDefermentAggLatest({
		from_date: fromTime,
		to_date: toTime,
		});
	}


	var last_7 = setpointV2Module.tasqMCFData["last_7"]
	var projected_3 = setpointV2Module.tasqMCFData["best_3"]

	var lift_pressure_current = setpointV2Module.tasqListPressureData["Current"]
	var lift_pressure_best = setpointV2Module.tasqListPressureData["Best"]

	var trying: any[] = []
	for (var x = 0; x < lift_pressure_current.length; x++) {
		var d = new Date();
 		d.setDate(d.getDate()-x);
		trying.push(d.toLocaleString())
	}




	this.chartData = {
		"labels": ["2021-08-21T13:05", "2021-08-21T13:10", "2021-08-21T13:15", "2021-08-21T13:20", "2021-08-21T13:25", "2021-08-21T13:30", "2021-08-21T13:35", "2021-08-21T13:40", "2021-08-21T13:45"],
		"datasets": [{
			"label": "",
			// "data": [270.2, 269.2, 268.1, 267.1, 267.3, 267.5, 267.7, 268.3, 268.8, 269.4, 268.8, 268.3, 267.7, 267.8, 267.9, 268, 269.3, 270.7, 272, 271.1],
			"data": last_7,
			"borderColor": "#2CE6C2",
			"borderWidth": 1.5,
			"pointBackgroundColor": "#0076FF",
			"pointBorderColor": "#0076FF",
			"pointRadius": 0,
			"backgroundColor": "rgba(44,230,194,0.1)",
			"fill": true,
			"showLine": true,
			"tension": 0,
			"spanGaps": true,
			"pointHitRadius": 5,
			"pointHoverRadius": 0
		},{
			"label": "",
			// "data": [270.2, 269.2, 268.1, 267.1, 267.3, 267.5, 267.7, 268.3, 268.8, 269.4, 268.8, 268.3, 267.7, 267.8, 267.9, 268, 269.3, 270.7, 272, 271.1],
			"data": [null, null, null, null, null, null, ...projected_3],
			"borderColor": "#2CE6C2",
			"borderWidth": 1.5,
			"pointBackgroundColor": "#0076FF",
			"pointBorderColor": "#0076FF",
			"pointRadius": 0,
			"backgroundColor": "rgba(44,230,194,0.1)",
			"fill": true,
			"showLine": true,
			"tension": 0,
			"spanGaps": true,
			"pointHitRadius": 5,
			"pointHoverRadius": 0
		}
		]
	}


	this.chartLoaded = true

  }




  async changeChartTime(value = 14) {
	  await setpointV2Module.getSetpointRecV2ForTasq({wellName: this.tasq.wellName})
  }




};
